import { useMemo } from 'react'; // routes
import { paths } from 'src/routes/paths'; // locales
import { useLocales } from 'src/locales'; // components
import SvgColor from 'src/components/svg-color';
import { isAdmin } from '../../utils/user-util';
import { useAuthContext } from '../../auth/hooks'; // ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

function filterMenusByRole(role, items) {
  return items.filter((item) => item.displayTo.includes(role));
}

function isUserRole(user) {
  return user?.role === 'USER';
}

export function useNavData() {
  const { t } = useLocales();
  const { user } = useAuthContext();

  const data = useMemo(() => {
    const generalMenu = {
      subheader: t('overview'),
      items: filterMenusByRole(user?.role, [
        {
          title: t('app'),
          path: paths.dashboard.root,
          icon: ICONS.dashboard,
          displayTo: ['ADMIN', 'DEALER_ADMIN', 'USER'],
        },
        // MYORDER
        {
          title: t('menu_my_order'),
          path: paths.dashboard.orders.root,
          icon: ICONS.order,
          children: [
            { title: t('list'), path: paths.dashboard.orders.root },
            ...(!isAdmin(user) ? [{ title: t('new'), path: paths.dashboard.orders.new }] : []),
          ],
          displayTo: ['ADMIN', 'DEALER_ADMIN', 'USER'],
        },
      ]),
    };

    const managementItems = {
      subheader: t('management'),
      items: filterMenusByRole(user?.role, [
        // MYPRODUCT
        {
          title: t('menu_my_product'),
          path: paths.dashboard.products.root,
          icon: ICONS.product,
          children: [
            { title: t('list'), path: paths.dashboard.products.root },
            { title: t('new'), path: paths.dashboard.products.new },
          ],
          displayTo: ['ADMIN'],
        },
        {
          title: t('menu_print_order'),
          path: paths.dashboard.print.orders,
          icon: ICONS.job,
          displayTo: ['ADMIN'],
        },
        {
          title: t('Reports'),
          path: paths.dashboard.reports.root,
          icon: ICONS.blank,
          children: [
            ...(!isUserRole(user)
              ? [{ title: t('Orders Amount'), path: paths.dashboard.reports.orderAmount }]
              : []),
            {
              title: t('Orders Count By Product And Quantity'),
              path: paths.dashboard.reports.ordersCountByProductAndQty,
            },
          ],
          displayTo: ['ADMIN', 'DEALER_ADMIN', 'USER'],
        },
        {
          title: t('menu_dealer'),
          path: paths.dashboard.dealers.root,
          icon: ICONS.user,
          children: [
            { title: t('list'), path: paths.dashboard.dealers.root },
            // { title: t('edit'), path: paths.dashboard.dealers.edit },
          ],
          displayTo: ['ADMIN'],
        },
        {
          title: t('menu_sku'),
          path: paths.dashboard.skus.root,
          icon: ICONS.ecommerce,
          children: [
            { title: t('list'), path: paths.dashboard.skus.root },
            { title: t('new'), path: paths.dashboard.skus.new },
            // { title: t('edit'), path: paths.dashboard.dealers.edit },
          ],
          displayTo: ['ADMIN'],
        },
        {
          title: t('menu_stock'),
          path: paths.dashboard.stocks.root,
          icon: ICONS.menuItem,
          children: [{ title: t('list'), path: paths.dashboard.stocks.root }],
          displayTo: ['ADMIN'],
        },
        {
          title: t('menu_quota'),
          path: paths.dashboard.quotas.root,
          icon: ICONS.label,
          children: [{ title: t('list'), path: paths.dashboard.quotas.root }],
          displayTo: ['ADMIN', 'DEALER_ADMIN'],
        },
        {
          title: t('menu_followUp'),
          path: paths.dashboard.followUp.root,
          icon: ICONS.label,
          children: [{ title: t('list'), path: paths.dashboard.followUp.root }],
          displayTo: ['ADMIN', 'DEALER_ADMIN', 'USER'],
        },
      ]),
    };

    return [generalMenu, managementItems];
  }, [t, user]);

  return data;
}
